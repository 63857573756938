import { SVGProps } from 'react';

export interface IconMetalProps extends SVGProps<SVGSVGElement> {
	className?: string;
	testId?: string;
}

const IconMetal = (props: IconMetalProps) => {
	const { testId = 'IconMetal', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 40 40"
			fill="none"
			aria-label="Metal Icon"
			{...remainingProps}
		>
			<path
				d="M20 5.625c5.75 0 10 2.625 10 5s-4.25 5-10 5-10-2.625-10-5 4.25-5 10-5Zm0-2.5c-6.875 0-12.5 3.375-12.5 7.5s5.625 7.5 12.5 7.5 12.5-3.375 12.5-7.5-5.625-7.5-12.5-7.5ZM20 30c-2.875 0-5.5-.625-7.5-1.625v1.375c2.063.938 4.688 1.5 7.5 1.5 2.813 0 5.438-.563 7.5-1.5v-1.375c-2 1-4.625 1.625-7.5 1.625ZM20 25.625c-2.875 0-5.5-.625-7.5-1.625v1.375c2.063.938 4.688 1.5 7.5 1.5 2.813 0 5.438-.563 7.5-1.5V24c-2 1-4.625 1.625-7.5 1.625ZM20 21.25c-2.875 0-5.5-.625-7.5-1.625V21c2.063.938 4.688 1.5 7.5 1.5 2.813 0 5.438-.563 7.5-1.5v-1.375c-2 1-4.625 1.625-7.5 1.625Z"
				fill="black"
			/>
			<path
				d="M32.5 10.625v18.75c0 4.125-5.625 7.5-12.5 7.5S7.5 33.5 7.5 29.375v-18.75H10v18.75c0 2.375 4.25 5 10 5s10-2.625 10-5v-18.75h2.5Z"
				fill="black"
			/>
		</svg>
	);
};

export default IconMetal;
