import { SVGProps } from 'react';

export interface IconCautionProps extends SVGProps<SVGSVGElement> {
	className?: string;
	testId?: string;
}

const IconCaution = (props: IconCautionProps) => {
	const { testId = 'IconCaution', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 40 40"
			fill="none"
			aria-label="Caution Icon"
			{...remainingProps}
		>
			<path
				d="M20.0003 6.25C18.8703 6.25 17.859 6.83437 17.294 7.8125L6.07818 27.2375C5.5138 28.2163 5.5144 29.3844 6.0794 30.3625C6.64377 31.3407 7.6557 31.925 8.7857 31.925H31.2149C32.3449 31.925 33.3568 31.3407 33.9212 30.3625C34.4862 29.3844 34.4868 28.2163 33.9224 27.2375L22.7066 7.8125C22.1422 6.83437 21.1303 6.25 20.0003 6.25ZM20.0003 8.75C20.109 8.75 20.3786 8.78063 20.5411 9.0625L31.7569 28.4875C31.92 28.7694 31.8113 29.0188 31.7569 29.1125C31.7025 29.2063 31.5411 29.425 31.2149 29.425H8.78448C8.45885 29.425 8.29808 29.2063 8.24371 29.1125C8.18933 29.0188 8.08058 28.7694 8.24371 28.4875L19.4595 9.0625C19.622 8.78063 19.8915 8.75 20.0003 8.75ZM19.9905 13.1714C19.558 13.1714 19.2071 13.3037 18.9371 13.5681C18.6664 13.8319 18.5382 14.1619 18.5501 14.5581L18.7479 21.9629C18.7722 22.7916 19.192 23.2068 20.0088 23.2068C20.802 23.2068 21.2049 22.7922 21.2161 21.9641L21.4505 14.5764C21.4624 14.1802 21.3277 13.8467 21.0452 13.5767C20.7633 13.306 20.4112 13.1714 19.9905 13.1714ZM20.0003 24.8572C19.0409 24.8572 18.4329 25.6681 18.4329 26.3562C18.4329 27.0443 19.0203 27.8552 20.0003 27.8552C20.9803 27.8552 21.5677 27.0881 21.5677 26.3562C21.5677 25.6243 20.9597 24.8572 20.0003 24.8572Z"
				fill="black"
			/>
		</svg>
	);
};

export default IconCaution;
