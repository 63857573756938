import { SVGProps } from 'react';

export interface IconTrashCanProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const IconTrashCan = (props: IconTrashCanProps) => {
	const { testId = 'IconTrashCan' } = props;

	return (
		<svg
			data-testid={testId}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 28 28"
			fill="none"
			aria-label="Trash Can Icon"
			{...props}
		>
			<path d="M12.25 3.063a2.19 2.19 0 0 0-2.188 2.187v1.313H5.689a.875.875 0 0 0 0 1.75h.922l.737 13.27a2.626 2.626 0 0 0 2.62 2.48h8.065a2.627 2.627 0 0 0 2.621-2.48l.737-13.27h.922a.875.875 0 0 0 0-1.75h-4.375V5.25a2.19 2.19 0 0 0-2.187-2.188h-3.5Zm0 1.75h3.5c.242 0 .438.196.438.437v1.313h-4.375V5.25c0-.241.195-.438.437-.438Zm-3.888 3.5h11.276l-.732 13.172a.875.875 0 0 1-.874.828H9.968a.875.875 0 0 1-.874-.827L8.362 8.312ZM14 10.171a.766.766 0 0 0-.766.765v8.75a.766.766 0 1 0 1.532 0v-8.75a.766.766 0 0 0-.766-.765Zm-3.219 0a.766.766 0 0 0-.738.792l.305 8.788c.014.413.354.739.765.739h.027a.766.766 0 0 0 .738-.792l-.305-8.788a.77.77 0 0 0-.792-.738Zm6.437 0a.767.767 0 0 0-.791.739l-.305 8.788a.766.766 0 0 0 .738.792h.027c.41 0 .75-.326.765-.74l.305-8.787a.766.766 0 0 0-.739-.791Z" />
		</svg>
	);
};

export default IconTrashCan;
