import { SVGProps } from 'react';

export interface IconMoneyHandProps extends SVGProps<SVGSVGElement> {
	testId?: string;
	className?: string;
}

const IconMoneyHand = (props: IconMoneyHandProps) => {
	const { testId = 'IconMoneyHand', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 40 40"
			aria-label="money in hand icon"
			className={className}
			{...remainingProps}
		>
			<path d="M26.875 5c-.32 0-.64.122-.884.366L22.607 8.75h-7.175c-1.276 0-2.524.24-3.709.714a7.448 7.448 0 0 1-2.78.536h-.396a4.356 4.356 0 0 0-4.176 3.07l-1.505 4.82a7.491 7.491 0 0 0 1.78 7.464c1.694 1.741 3.893 3.994 4.75 4.833l.332.328c1.562 1.55 3.877 3.847 6.342 3.847.157 0 .314-.01.473-.03.963-.118 1.8-.592 2.501-1.406.486.167.97.244 1.442.18 1.037-.138 1.945-.764 2.71-1.88.651.237 1.373.211 2.092.007 1.069-.304 1.961-1.04 2.551-2.086 1.091.168 2.221-.199 2.981-.958a3.44 3.44 0 0 0 .34-4.456l6.599-6.6a1.25 1.25 0 0 0 0-1.767l-10-10A1.246 1.246 0 0 0 26.875 5Zm0 3.018 8.232 8.232-5.69 5.69-.665-.66 4.809-4.81a.314.314 0 0 0 0-.44l-6.465-6.465a.313.313 0 0 0-.442 0l-4.841 4.842-.667-.66 5.729-5.73Zm0 2.21 6.023 6.022-4.59 4.591-.64-.634c.458-1.201.127-2.645-.963-3.735-1.1-1.1-2.559-1.425-3.767-.95l-.682-.676 4.619-4.619ZM15.432 11.25h4.675l-1.616 1.616-.005.005a1.25 1.25 0 0 0 .01 1.768L29.05 25.095c.366.366.367.96.002 1.326a.962.962 0 0 1-1.327 0l-3.315-3.314a1.25 1.25 0 0 0-1.767 1.767l3.032 3.033c-.262.475-.628.8-1.048.915-.305.084-.602.041-.778-.111-.701-.61-2.91-2.897-2.91-2.897a1.249 1.249 0 1 0-1.796 1.74l2.105 2.09c-.363.587-.735.94-1.055.979-.284.036-1.12-.104-2.754-2.068a1.25 1.25 0 0 0-1.76-.161 1.247 1.247 0 0 0-.163 1.76c.442.526.898.998 1.36 1.41-.3.244-.535.275-.64.288-1.42.177-3.591-1.962-4.75-3.112l-.343-.34c-.664-.65-2.29-2.305-4.706-4.79a4.994 4.994 0 0 1-1.186-4.975l1.506-4.82a1.867 1.867 0 0 1 1.79-1.315h.395c1.276 0 2.523-.24 3.708-.714a7.448 7.448 0 0 1 2.78-.536Zm8.809 4.692c.703.047 1.425.375 2.022.972.82.82 1.117 1.868.894 2.787l-3.709-3.673c.258-.066.522-.103.793-.086Z" />
		</svg>
	);
};

export default IconMoneyHand;
