import classNames from 'classnames';
import { SVGProps } from 'react';

export interface IconArrowRightProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const IconArrowRight = (props: IconArrowRightProps) => {
	const { testId = 'IconArrowRight', className, ...remainingProps } = props;

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 28 28"
			className={classNames('inline-block', className)}
			aria-label="Arrow Right"
			data-testid={testId}
			{...remainingProps}
		>
			<path d="M16.427 6.58a.873.873 0 0 0-.643 1.484l4.9 5.061H5.25a.875.875 0 0 0 0 1.75h15.435l-4.9 5.061a.875.875 0 0 0 1.256 1.218l6.337-6.546a.874.874 0 0 0 0-1.216l-6.337-6.546a.87.87 0 0 0-.614-.266Z" />
		</svg>
	);
};

export default IconArrowRight;
