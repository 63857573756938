import { SVGProps } from 'react';

export interface IconAlarmClockProps extends SVGProps<SVGSVGElement> {
	className?: string;
	testId?: string;
}

const IconAlarmClock = (props: IconAlarmClockProps) => {
	const { testId = 'IconAlarmClock', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 40 40"
			fill="none"
			aria-label="Alarm Clock Icon"
			{...remainingProps}
		>
			<path
				d="M12.1359 6.32818C11.21 6.29033 10.2656 6.56183 9.46987 7.16558L8.66543 7.77715C6.84606 9.15777 6.49135 11.7519 7.87197 13.5706C8.2476 14.0656 8.9529 14.1623 9.4479 13.7867L15.0473 9.53618C15.5416 9.16056 15.639 8.45526 15.2633 7.96026C14.4867 6.93721 13.3263 6.37684 12.1359 6.32818ZM27.8647 6.32818C26.6741 6.37662 25.5126 6.93686 24.736 7.96026C24.3604 8.45526 24.4571 9.16178 24.9521 9.5374L30.5526 13.7879C31.0476 14.1635 31.7529 14.0668 32.1286 13.5718C33.5092 11.7531 33.1532 9.15837 31.3351 7.77837L30.5307 7.1668C29.7347 6.56277 28.7906 6.29049 27.8647 6.32818ZM19.9997 10C13.4515 10 8.12466 15.3269 8.12466 21.875C8.12466 25.0177 9.3595 27.8714 11.3595 29.9976L9.11587 32.2413C8.62775 32.7294 8.62774 33.5207 9.11587 34.0088C9.36024 34.2532 9.67966 34.375 9.99966 34.375C10.3197 34.375 10.6391 34.2532 10.8834 34.0088L13.2553 31.637C15.1728 32.966 17.495 33.75 19.9997 33.75C22.5043 33.75 24.8265 32.966 26.744 31.637L29.1159 34.0088C29.3602 34.2532 29.6797 34.375 29.9997 34.375C30.3197 34.375 30.6391 34.2532 30.8834 34.0088C31.3716 33.5207 31.3716 32.7294 30.8834 32.2413L28.6398 29.9976C30.6398 27.8714 31.8747 25.0177 31.8747 21.875C31.8747 15.3269 26.5478 10 19.9997 10ZM19.9997 12.5C25.169 12.5 29.3747 16.7057 29.3747 21.875C29.3747 27.0444 25.169 31.25 19.9997 31.25C14.8303 31.25 10.6247 27.0444 10.6247 21.875C10.6247 16.7057 14.8303 12.5 19.9997 12.5ZM19.9997 13.75C19.3097 13.75 18.7497 14.3094 18.7497 15V20.625H14.3747C13.684 20.625 13.1247 21.185 13.1247 21.875C13.1247 22.565 13.684 23.125 14.3747 23.125H19.9997C20.6897 23.125 21.2497 22.5657 21.2497 21.875V15C21.2497 14.3094 20.6897 13.75 19.9997 13.75Z"
				fill="black"
			/>
		</svg>
	);
};

export default IconAlarmClock;
