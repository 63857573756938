import { SVGProps } from 'react';

export interface IconScheduleProps extends SVGProps<SVGSVGElement> {
	className?: string;
	testId?: string;
}

const IconSchedule = (props: IconScheduleProps) => {
	const { testId = 'IconSchedule', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 40 40"
			fill="none"
			aria-label="Calendar Icon"
			{...remainingProps}
		>
			<path
				d="M12.5 6.25C8.70937 6.25 5.625 9.33437 5.625 13.125C5.625 14.5909 6.09004 15.9483 6.875 17.0654V28.75C6.875 30.8181 8.55687 32.5 10.625 32.5H29.375C31.4431 32.5 33.125 30.8181 33.125 28.75V11.25C33.125 9.18187 31.4431 7.5 29.375 7.5H16.4404C15.3233 6.71504 13.9659 6.25 12.5 6.25ZM12.5 8.75C14.9119 8.75 16.875 10.7125 16.875 13.125C16.875 15.5375 14.9119 17.5 12.5 17.5C10.0881 17.5 8.125 15.5375 8.125 13.125C8.125 10.7125 10.0881 8.75 12.5 8.75ZM12.1875 10.3125C11.67 10.3125 11.25 10.7319 11.25 11.25V13.4546C11.25 13.7052 11.3508 13.9461 11.5295 14.1223L12.7795 15.3552C12.962 15.5352 13.1994 15.625 13.4375 15.625C13.68 15.625 13.9208 15.5317 14.1052 15.3455C14.4683 14.9767 14.4648 14.3835 14.0955 14.0198L13.125 13.0627V11.25C13.125 10.7319 12.705 10.3125 12.1875 10.3125ZM19.2554 14.375H29.375C30.0644 14.375 30.625 14.9356 30.625 15.625V28.75C30.625 29.4394 30.0644 30 29.375 30H10.625C9.93563 30 9.375 29.4394 9.375 28.75V19.2407C10.3139 19.7226 11.3742 20 12.5 20C15.8634 20 18.6653 17.5704 19.2554 14.375ZM23.125 21.25C22.4344 21.25 21.875 21.8094 21.875 22.5V25.625C21.875 26.3156 22.4344 26.875 23.125 26.875H26.25C26.9406 26.875 27.5 26.3156 27.5 25.625V22.5C27.5 21.8094 26.9406 21.25 26.25 21.25H23.125Z"
				fill="black"
			/>
		</svg>
	);
};

export default IconSchedule;
