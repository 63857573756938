import { SVGProps } from 'react';

export interface IconThermometerProps extends SVGProps<SVGSVGElement> {
	className?: string;
	testId?: string;
}

const IconThermometerChange = (props: IconThermometerProps) => {
	const {
		testId = 'IconThermometerChange',
		className,
		...remainingProps
	} = props;

	return (
		<svg
			data-testid={testId}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 40 40"
			fill="none"
			aria-label="Thermometer with Up/Down Arrows Icon"
			{...remainingProps}
		>
			<path
				d="M15.417 25.2498V16.8332C15.417 16.1665 14.8337 15.5832 14.167 15.5832C13.5003 15.5832 12.917 16.1665 12.917 16.8332V25.2498C11.8337 25.7498 11.0837 26.8332 11.0837 28.0832C11.0837 29.8332 12.5003 31.2498 14.2503 31.2498C16.0003 31.2498 17.417 29.8332 17.417 28.0832C17.3337 26.8332 16.5003 25.7498 15.417 25.2498Z"
				fill="black"
			/>
			<path
				d="M19.8337 9.33317C19.8337 6.24984 17.3337 3.6665 14.167 3.6665C11.0003 3.6665 8.50033 6.1665 8.50033 9.33317V23.1665C7.33366 24.4998 6.66699 26.2498 6.66699 28.1665C6.66699 32.3332 10.0003 35.6665 14.167 35.6665C18.3337 35.6665 21.667 32.3332 21.667 28.1665C21.667 26.3332 21.0003 24.5832 19.8337 23.2498V9.33317ZM14.167 33.1665C11.417 33.1665 9.16699 30.9165 9.16699 28.1665C9.16699 26.8332 9.66699 25.5832 10.667 24.5832C10.917 24.3332 11.0003 23.9998 11.0003 23.6665V9.33317C11.0003 7.58317 12.417 6.1665 14.167 6.1665C15.917 6.1665 17.3337 7.58317 17.3337 9.33317V23.5832C17.3337 23.9165 17.5003 24.2498 17.667 24.4998C18.667 25.4165 19.167 26.7498 19.167 28.0832C19.167 30.9165 16.917 33.1665 14.167 33.1665Z"
				fill="black"
			/>
			<path
				d="M32.167 23.9998H29.8337V16.0832H32.167C32.8337 16.0832 33.167 15.3332 32.7503 14.8332L29.167 9.9165C29.0003 9.6665 28.7503 9.58317 28.5837 9.58317C28.3337 9.58317 28.0837 9.6665 28.0003 9.9165L24.417 14.8332C24.0837 15.3332 24.417 16.0832 25.0003 16.0832H27.3337V23.9998H25.0003C24.3337 23.9998 24.0003 24.7498 24.417 25.2498L28.0003 30.1665C28.3337 30.5832 28.917 30.5832 29.2503 30.1665L32.8337 25.2498C33.167 24.6665 32.7503 23.9998 32.167 23.9998Z"
				fill="black"
			/>
		</svg>
	);
};

export default IconThermometerChange;
