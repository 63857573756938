import { SVGProps } from 'react';

export interface IconElectricProps extends SVGProps<SVGSVGElement> {
	className?: string;
	testId?: string;
}

const IconElectric = (props: IconElectricProps) => {
	const { testId = 'IconElectric', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 40 40"
			fill="none"
			aria-label="Lightning Bolt Icon"
			{...remainingProps}
		>
			<path
				d="M18.5702 34.9294C18.2952 34.9294 18.0152 34.8838 17.7395 34.7888C16.5952 34.3944 15.9108 33.2813 16.077 32.0819L17.3152 23.125H13.9489C13.2214 23.125 12.5308 22.8088 12.0564 22.2575C11.5808 21.7063 11.3695 20.9775 11.4764 20.2575L13.4239 7.13313C13.6052 5.91688 14.6683 5 15.8977 5H22.3639C23.1727 5 23.9352 5.39375 24.4033 6.05375C24.8708 6.71312 24.9908 7.56312 24.7239 8.32625L22.387 15H27.2052C28.1102 15 28.9202 15.4681 29.372 16.2525C29.8239 17.0363 29.822 17.9713 29.3677 18.7544L20.7164 33.6788C20.2552 34.4744 19.4383 34.9294 18.5702 34.9294ZM16.167 7.5C16.012 7.5 15.8808 7.61313 15.8577 7.76688L14.0027 20.2669C13.9895 20.3569 14.0158 20.4481 14.0752 20.5169C14.1345 20.5856 14.2214 20.625 14.312 20.625H18.7502C19.1114 20.625 19.4552 20.7813 19.6927 21.0538C19.9302 21.3263 20.0383 21.6881 19.9889 22.0463L18.6395 31.82C18.6314 31.8794 18.6664 31.9356 18.7227 31.9556C18.7789 31.975 18.8414 31.9519 18.8714 31.9L26.9339 17.9688C26.9902 17.8719 26.9902 17.7531 26.9339 17.6563C26.8783 17.5594 26.7752 17.5 26.6633 17.5H20.6252C20.2202 17.5 19.8402 17.3038 19.6058 16.9731C19.3714 16.6425 19.3114 16.2194 19.4452 15.8369L22.2177 7.91563C22.2514 7.82 22.2364 7.71438 22.1777 7.63188C22.1195 7.54938 22.0245 7.5 21.9227 7.5H16.167Z"
				fill="black"
			/>
		</svg>
	);
};

export default IconElectric;
