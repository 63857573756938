import { SVGProps } from 'react';

export interface IconMoneyCashProps extends SVGProps<SVGSVGElement> {
	className?: string;
	testId?: string;
}

const IconMoneyCash = (props: IconMoneyCashProps) => {
	const { testId = 'IconMoneyCash', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 40 40"
			fill="none"
			aria-label="Cash money Icon"
			{...remainingProps}
		>
			<path
				d="M5 7.5C3.61937 7.5 2.5 8.61937 2.5 10V26.25C2.5 27.173 3.00587 27.9702 3.75 28.4033V29.5886C3.75 31.288 5.13111 32.6893 6.82861 32.7124C16.4099 32.8399 28.1973 33.9079 32.8198 34.3604C32.9211 34.3704 33.0219 34.375 33.1226 34.375C33.8951 34.375 34.6484 34.087 35.2246 33.5645C35.8765 32.9732 36.25 32.13 36.25 31.25V28.4033C36.9941 27.9702 37.5 27.173 37.5 26.25V10C37.5 8.61937 36.3806 7.5 35 7.5H5ZM5.625 10H34.375C34.72 10 35 10.28 35 10.625V25.625C35 25.97 34.72 26.25 34.375 26.25H5.625C5.28 26.25 5 25.97 5 25.625V10.625C5 10.28 5.28 10 5.625 10ZM6.25 11.25V25H20H33.75V11.25H20H6.25ZM6.875 11.875H17.9285C16.2035 12.9604 15 15.3508 15 18.125C15 20.8992 16.2035 23.2896 17.9285 24.375H6.875V11.875ZM20 11.875C22.4119 11.875 24.375 14.6788 24.375 18.125C24.375 21.5712 22.4119 24.375 20 24.375C17.5881 24.375 15.625 21.5712 15.625 18.125C15.625 14.6788 17.5881 11.875 20 11.875ZM22.0715 11.875H33.125V24.375H22.0715C23.7965 23.2896 25 20.8992 25 18.125C25 15.3508 23.7965 12.9604 22.0715 11.875ZM6.25 28.75H33.75V31.25C33.75 31.4906 33.6224 31.6426 33.5461 31.7126C33.4686 31.7814 33.3008 31.8945 33.064 31.8713C28.409 31.4151 16.5378 30.3418 6.86157 30.2124C6.51907 30.208 6.25 29.9336 6.25 29.5886V28.75Z"
				fill="black"
			/>
		</svg>
	);
};

export default IconMoneyCash;
