import { SVGProps } from 'react';

export interface IconHeatingProps extends SVGProps<SVGSVGElement> {
	className?: string;
	testId?: string;
}

const IconHeating = (props: IconHeatingProps) => {
	const { testId = 'IconHeating', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 40 40"
			fill="none"
			aria-label="Flame Icon"
			{...remainingProps}
		>
			<path
				d="M18.9116 4.61903C18.5636 4.6245 18.2149 4.68919 17.8777 4.81434C16.9595 5.15497 16.2611 5.88856 15.9624 6.82606C14.7824 10.5292 13.1333 12.7213 11.6789 14.6557C10.1752 16.6557 8.75521 18.5452 8.29271 21.4965C7.71209 25.2002 8.82555 29.1192 11.2737 31.9811C13.6287 34.7336 16.9499 36.2499 20.6255 36.2499C27.728 36.2499 32.5005 31.1011 32.5005 23.4374C32.5005 16.6393 27.9322 9.60545 20.5791 5.08045C20.0685 4.76639 19.4916 4.60991 18.9116 4.61903ZM18.9885 7.11048C19.0812 7.12049 19.175 7.1528 19.2668 7.20936C25.7868 11.2206 30.0005 17.5911 30.0005 23.4374C30.0005 26.2746 29.2388 28.6498 27.8838 30.4076C28.0356 29.7789 28.1255 29.1219 28.1255 28.4374C28.1255 20.7293 22.8689 16.5335 22.6457 16.3585C22.2401 16.0417 21.6795 16.0069 21.2383 16.2694C20.7951 16.5325 20.5582 17.0406 20.6426 17.5487C20.9488 19.4025 21.0498 22.5625 20.291 23.4569C20.2016 23.5619 20.1342 23.5936 20.0005 23.5936C19.6917 23.5936 18.9871 22.2865 18.7346 20.7396C18.6584 20.2621 18.3134 19.8706 17.8484 19.7362C17.3834 19.6018 16.8836 19.7478 16.563 20.111C13.7217 23.331 13.1255 26.4161 13.1255 28.4374C13.1255 29.2006 13.2411 29.941 13.4477 30.6444C13.3574 30.5469 13.2602 30.4581 13.1731 30.3563C11.1937 28.042 10.2934 24.8753 10.7622 21.8834C11.1284 19.5491 12.2529 18.0527 13.6772 16.1583C15.1735 14.169 17.0309 11.691 18.344 7.58534C18.4499 7.25299 18.7103 7.08045 18.9885 7.11048ZM23.3733 20.9252C24.5014 22.6302 25.6255 25.1524 25.6255 28.4374C25.6255 31.013 23.873 33.7499 20.6255 33.7499C17.9617 33.7499 15.6255 31.2674 15.6255 28.4374C15.6255 27.2436 15.9001 25.5657 17.0207 23.6889C17.5789 24.9139 18.5042 26.0936 20.0005 26.0936C20.873 26.0936 21.6327 25.74 22.199 25.0719C23.0415 24.0775 23.3245 22.4571 23.3733 20.9252Z"
				fill="black"
			/>
		</svg>
	);
};

export default IconHeating;
