import { SVGProps } from 'react';

export interface IconInfoBubbleProps extends SVGProps<SVGSVGElement> {
	className?: string;
	testId?: string;
}

const IconInfoBubble = (props: IconInfoBubbleProps) => {
	const { testId = 'IconInfoBubble', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
			aria-label="Info Bubble Icon"
			{...remainingProps}
		>
			<path d="M12 3.75a8.25 8.25 0 1 0 0 16.5 8.25 8.25 0 0 0 0-16.5Zm0 1.5a6.75 6.75 0 1 1 0 13.5 6.75 6.75 0 0 1 0-13.5Zm0 2.625a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25Zm0 3.375a.75.75 0 0 0-.75.75v3.75a.75.75 0 0 0 1.5 0V12a.75.75 0 0 0-.75-.75Z" />
		</svg>
	);
};

export default IconInfoBubble;
