import { SVGProps } from 'react';

export interface IconPVCProps extends SVGProps<SVGSVGElement> {
	className?: string;
	testId?: string;
}

const IconPVC = (props: IconPVCProps) => {
	const { testId = 'IconPVC', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 40 40"
			fill="none"
			aria-label="PVC Icon"
			{...remainingProps}
		>
			<path
				d="M20 5C25.75 5 30 7.625 30 10C30 12.375 25.75 15 20 15C14.25 15 10 12.375 10 10C10 7.625 14.25 5 20 5ZM20 2.5C13.125 2.5 7.5 5.875 7.5 10C7.5 14.125 13.125 17.5 20 17.5C26.875 17.5 32.5 14.125 32.5 10C32.5 5.875 26.875 2.5 20 2.5Z"
				fill="black"
			/>
			<path
				d="M32.5 10V30C32.5 34.125 26.875 37.5 20 37.5C13.125 37.5 7.5 34.125 7.5 30V10H10V30C10 32.375 14.25 35 20 35C25.75 35 30 32.375 30 30V10H32.5Z"
				fill="black"
			/>
			<path
				d="M11.4375 26.6875C11.4375 24.125 11.4375 22.875 11.4375 20.3125C12.375 20.75 12.875 20.9375 13.875 21.25C14.3125 21.375 14.75 21.625 15.0625 21.875C15.375 22.125 15.625 22.4375 15.8125 22.8125C16 23.1875 16.0625 23.5625 16.0625 24C16.0625 24.4375 16 24.8125 15.8125 25.125C15.625 25.4375 15.375 25.625 15 25.6875C14.6875 25.75 14.25 25.75 13.8125 25.625C13.1875 25.4375 12.875 25.3125 12.3125 25.0625V23.875C12.8125 24.0625 13.0625 24.1875 13.5625 24.375C13.8125 24.4375 13.9375 24.4375 14.125 24.375C14.25 24.375 14.375 24.25 14.4375 24.125C14.5 24 14.5625 23.8125 14.5625 23.625C14.5625 23.4375 14.5625 23.25 14.4375 23.0625C14.375 22.875 14.25 22.75 14.125 22.625C14 22.5 13.8125 22.375 13.5625 22.3125C13.25 22.1875 13.125 22.1875 12.875 22.0625C12.875 24.125 12.875 25.125 12.875 27.1875C12.3125 27 12 26.875 11.4375 26.5625V26.6875Z"
				fill="black"
			/>
			<path
				d="M18.3125 22.1875C18.875 24.125 19.125 25.0625 19.6875 26.9375C20.25 25.0625 20.5 24.125 21.0625 22.25C21.75 22.25 22.0625 22.1875 22.75 22.0625C21.9375 24.75 21.5 26.0625 20.625 28.625C19.8125 28.625 19.4375 28.625 18.625 28.625C17.75 26 17.375 24.6875 16.5 22C17.1875 22.125 17.5 22.1875 18.1875 22.1875H18.3125Z"
				fill="black"
			/>
			<path
				d="M28.625 22.625C28.0625 22.875 27.75 23 27.1875 23.25C27.1875 23.125 27.1875 22.9375 27.0625 22.875C27.0625 22.75 26.9375 22.6875 26.8125 22.625C26.6875 22.625 26.625 22.5625 26.4375 22.5625C26.3125 22.5625 26.125 22.5625 26 22.625C25.6875 22.6875 25.4375 22.875 25.25 23.0625C25.0625 23.25 24.875 23.5 24.75 23.8125C24.625 24.125 24.5625 24.5 24.5625 24.875C24.5625 25.25 24.5625 25.625 24.75 25.875C24.875 26.125 25 26.3125 25.25 26.375C25.4375 26.4375 25.6875 26.4375 26 26.375C26.1875 26.375 26.3125 26.25 26.4375 26.1875C26.5625 26.125 26.6875 26 26.8125 25.875C26.9375 25.75 27 25.625 27.0625 25.5C27.125 25.375 27.1875 25.1875 27.1875 25.0625C27.75 24.875 28.0625 24.75 28.625 24.4375C28.625 24.75 28.5 25.0625 28.375 25.375C28.25 25.6875 28.0625 26 27.875 26.375C27.6875 26.75 27.375 27 27.0625 27.25C26.75 27.5 26.375 27.6875 25.9375 27.8125C25.375 28 24.875 28 24.4375 27.8125C24 27.6875 23.625 27.375 23.375 26.9375C23.125 26.5 23 25.9375 23 25.25C23 24.5625 23.125 23.9375 23.375 23.4375C23.625 22.875 24 22.5 24.4375 22.125C24.875 21.75 25.375 21.5 25.875 21.375C26.25 21.25 26.5625 21.1875 26.875 21.1875C27.1875 21.1875 27.4375 21.1875 27.6875 21.3125C27.9375 21.4375 28.125 21.5625 28.25 21.8125C28.375 22.0625 28.5 22.3125 28.5 22.6875L28.625 22.625Z"
				fill="black"
			/>
		</svg>
	);
};

export default IconPVC;
