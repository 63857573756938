import { SVGProps } from 'react';

export interface IconRelaxProps extends SVGProps<SVGSVGElement> {
	className?: string;
	testId?: string;
}

const IconRelax = (props: IconRelaxProps) => {
	const { testId = 'IconRelax', className, ...remainingProps } = props;

	return (
		<svg
			data-testid={testId}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 40 40"
			fill="none"
			aria-label="Couch Icon"
			{...remainingProps}
		>
			<path
				d="M12.5 10a3.755 3.755 0 0 0-3.75 3.75v2.5h-.625c-1.006 0-1.945.39-2.649 1.101A3.699 3.699 0 0 0 4.375 20a3.7 3.7 0 0 0 1.102 2.65c.048.048.097.096.148.142V25a5 5 0 0 0 2.5 4.324V30c0 .345.28.625.625.625h3.125c.345 0 .625-.28.625-.625h15c0 .345.28.625.625.625h3.125c.345 0 .625-.28.625-.625v-.676a5 5 0 0 0 2.5-4.324v-2.208c.05-.046.1-.093.148-.142A3.7 3.7 0 0 0 35.625 20a3.699 3.699 0 0 0-1.101-2.649 3.696 3.696 0 0 0-2.649-1.101h-.625v-2.5A3.755 3.755 0 0 0 27.5 10h-15Zm0 2.5h15c.688 0 1.25.563 1.25 1.25v3.604a4.99 4.99 0 0 0-1.875 3.896v.813c-1.48-.375-3.852-.813-6.875-.813s-5.396.438-6.875.813v-.813a4.99 4.99 0 0 0-1.875-3.896V13.75c0-.688.563-1.25 1.25-1.25Zm-4.375 6.25c1.379 0 2.5 1.121 2.5 2.5v2.5a1.25 1.25 0 0 0 1.711 1.162c.03-.012 3-1.162 7.664-1.162s7.635 1.15 7.661 1.16a1.246 1.246 0 0 0 1.165-.125c.343-.232.549-.62.549-1.035v-2.5c0-1.379 1.121-2.5 2.5-2.5.344 0 .656.137.881.369.232.225.369.537.369.881s-.137.656-.369.881a1.21 1.21 0 0 1-.487.305c-.246.082-.394.337-.394.596V25a2.5 2.5 0 0 1-2.5 2.5h-18.75a2.5 2.5 0 0 1-2.5-2.5v-3.218c0-.26-.148-.514-.394-.596a1.21 1.21 0 0 1-.487-.305A1.222 1.222 0 0 1 6.875 20c0-.344.137-.656.369-.881.225-.232.537-.369.881-.369Z"
				fill="black"
			/>
		</svg>
	);
};

export default IconRelax;
